export const pldtConfig = {
  //Tribal Config
  // appDomain: "http://pldtcms.tribalddbph.com",
  // apiUrl: "http://pldtcms.tribalddbph.com/"

  //Tribal Temp CMS Config
  // appDomain: "http://pldttempcms.tribalddbph.com",
  // apiUrl: "http://pldttempcms.tribalddbph.com/"

  //PLDT VPN Config
  // appDomain: "https://10.170.240.233:8181",
  // apiUrl: "https://10.170.240.233:8181/drupal/"

  //PLDT Config
  // appDomain: "https://10.170.240.232",
  // apiUrl: "https://10.170.240.232/drupal/"

  //PLDT Live CMS
  // appDomain: "https://cms.pldt.com",
  // apiUrl: "https://cms.pldt.com/drupal/",
  // downloadDomain: null

// PLDT-MAKE DEV CMS
appDomain:
process.env.REACT_APP_SITE_TYPE === "dev"
  ? "https://pldtcms-drupal.make.technology"
  : process.env.REACT_APP_SITE_TYPE === "pldt_staging"
  ? "https://10.170.240.233:8181"
  : process.env.REACT_APP_SITE_TYPE === "staging"
  ? "https://staging.pldtcms-drupal.make.technology"
  : "https://cms.pldt.com", // Default to live CMS

apiUrl:
process.env.REACT_APP_SITE_TYPE === "dev"
  ? "https://pldtcms-drupal.make.technology/"
  : process.env.REACT_APP_SITE_TYPE === "pldt_staging"
  ? "https://10.170.240.233:8181/drupal/"
  : process.env.REACT_APP_SITE_TYPE === "staging"
  ? "https://staging.pldtcms-drupal.make.technology/"
  : "https://cms.pldt.com/drupal/", // Default to live CMS

downloadDomain: null, // Can add more conditions if needed

  //Tribal Temp Files CMS Config
  // downloadDomain: "http://files.tribalddbph.com",
  // appDomain: "http://cmstestfiles.tribalddbph.com",
  // apiUrl: "http://cmstestfiles.tribalddbph.com/"
};
