import { pldtConfig } from "../config";
export const decodeHTMLEntities = (text) => {
  const parser = new DOMParser();
  const decodedString = parser.parseFromString(text, "text/html").body
    .textContent;
  return decodedString;
};

export const transformFieldsToLowercase = (data) => {
  return data.map((item) => {
    return Object.keys(item).reduce((acc, key) => {
      // Convert key to lowercase
      const lowerCaseKey = key.toLowerCase();
      acc[lowerCaseKey] = item[key];
      return acc;
    }, {});
  });
};

export const formatCta = (dataArray) => {
  return dataArray.map((obj) => {
    const parser = new DOMParser();
    const ctaDoc = parser.parseFromString(obj.cta, "text/html");

    const linkTitle = ctaDoc.querySelector(".link-title");
    const linkUrl = ctaDoc.querySelector(".link-url a");

    let ctaTitle = "";
    let ctaUrl = "";

    if (linkTitle) ctaTitle = linkTitle.textContent;
    if (linkUrl) ctaUrl = linkUrl.getAttribute("href");

    return {
      ...obj,
      cta: {
        title: ctaTitle,
        url: ctaUrl,
      },
    };
  });
};

export const transformMenu = (htmlString) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");
  const links = doc.querySelectorAll("a");

  return Array.from(links).map((link) => ({
    label: link.textContent,
    url: makeRelativePath(link.href),
  }));
};

const makeRelativePath = (url) => {
  const isExternal = /^(https?:\/\/|\/\/)/.test(url);

  if (!isExternal) {
    // Keep internal links as relative paths
    return url;
  }

  // If it's an external link, return as it is
  const currentOrigin = window.location.origin;

  // If the URL belongs to the same domain, strip the domain to make it a relative path
  if (url.startsWith(currentOrigin)) {
    return url.replace(currentOrigin, "");
  }

  // Keep external links unchanged
  return url;
};

export const getFile = async (query) => {
  const fetchURL = pldtConfig.apiUrl + `api/v1/get-file?filename=${query}`;

  try {
    const response = await fetch(fetchURL);
    const data = await response.json();
    const { filename } = data[0];
    const parser = new DOMParser();
    const doc = parser.parseFromString(filename, "text/html");
    const linkElement = doc.querySelector("a");
    return linkElement ? linkElement.getAttribute("href") : "";
  } catch (err) {
    console.error(err.message);
    return "";
  }
};
