import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MenuDropdown from "../inputs/MenuDropdown";
import MobileNav from "./MobileNav";
import SearchBar from "./SearchBar";
import { pldtConfig } from "../../config";

const Header = () => {
  // const desktopmenu = [
  //   {
  //     main: "About PLDT",
  //     pathname: "/about-us",
  //     submenu: [
  //       {
  //         menu: "Overview",
  //         subnav: "/about-us",
  //       },
  //       {
  //         menu: "What Drives Us",
  //         subnav: "/about-us/what-drives-us",
  //       },
  //       {
  //         menu: "PLDT's Business",
  //         subnav: "/about-us/pldts-business",
  //       },
  //       {
  //         menu: "Company Leadership",
  //         subnav: "/about-us/company-leadership",
  //       },
  //       {
  //         menu: "Company Timeline",
  //         subnav: "/about-us/company-timeline",
  //       },
  //       {
  //         menu: "Corporate Citizenship",
  //         subnav: [
  //           {
  //             nav: "Overview",
  //             link: "/about-us/corporate-citizenship",
  //           },
  //           {
  //             nav: "Stakeholder Management",
  //             link: "/about-us/corporate-citizenship/stakeholder-management",
  //           },
  //           {
  //             nav: "Shared Value Programs",
  //             link: "/about-us/corporate-citizenship/shared-value-programs",
  //           },
  //         ],
  //       },
  //       {
  //         menu: "Awards and Citations",
  //         subnav: "/about-us/awards-and-citations",
  //       },
  //     ],
  //   },
  //   {
  //     main: "Newsroom",
  //     submenu: "/newsroom",
  //   },
  //   {
  //     main: "Governance",
  //     pathname: "/corporate-governance-in-pldt",
  //     submenu: [
  //       {
  //         menu: "Overview",
  //         subnav: "/corporate-governance-in-pldt",
  //       },
  //       {
  //         menu: "Message from the Chairman",
  //         subnav: "/corporate-governance-in-pldt/message-from-the-chairman",
  //       },
  //       {
  //         menu: "Message from the President",
  //         subnav:
  //           "/corporate-governance-in-pldt/message-from-the-president-and-ceo",
  //       },
  //       {
  //         menu: "Governance Structure",
  //         subnav: [
  //           {
  //             nav: "Our Board of Directors",
  //             link: "/corporate-governance-in-pldt/governance-structures",
  //           },
  //           {
  //             nav: "About the Board",
  //             link: "/corporate-governance-in-pldt/governance-structures/about-the-board",
  //           },
  //           {
  //             nav: "Board Committees",
  //             link: "/corporate-governance-in-pldt/governance-structures/board-committees",
  //           },
  //           {
  //             nav: "Executive Implementation",
  //             link: "/corporate-governance-in-pldt/governance-structures/executive-implementation",
  //           },
  //           {
  //             nav: "CG Framework",
  //             link: "/corporate-governance-in-pldt/governance-structures/cg-framework",
  //           },
  //         ],
  //       },
  //       {
  //         menu: "Policies, Processes and Practices",
  //         subnav: [
  //           {
  //             nav: "Overview",
  //             link: "/corporate-governance-in-pldt/policies-processes-and-practices",
  //           },
  //           {
  //             nav: "Education and Communication",
  //             link: "/corporate-governance-in-pldt/policies-processes-and-practices/education-and-communication",
  //           },
  //           {
  //             nav: "Monitoring and Evaluation",
  //             link: "/corporate-governance-in-pldt/policies-processes-and-practices/monitoring-and-evaluation",
  //           },
  //           {
  //             nav: "Disclosures",
  //             link: "/corporate-governance-in-pldt/policies-processes-and-practices/disclosures",
  //           },
  //           {
  //             nav: "Networking",
  //             link: "/corporate-governance-in-pldt/policies-processes-and-practices/networking",
  //           },
  //         ],
  //       },
  //       {
  //         menu: "Sitemap",
  //         subnav: "/corporate-governance-in-pldt/site-map",
  //       },
  //       {
  //         menu: "Our Stakeholders",
  //         subnav: "/corporate-governance-in-pldt/our-stakeholders",
  //       },
  //     ],
  //   },
  //   {
  //     main: "Investor Relations",
  //     pathname: "/investor-relations",
  //     submenu: [
  //       {
  //         menu: "Overview",
  //         subnav: "/investor-relations",
  //       },
  //       {
  //         menu: "Annual and Sustainability Reports",
  //         subnav: "/investor-relations/annual-and-sustainability-reports",
  //       },
  //       {
  //         menu: "Annual Stockholders Meeting",
  //         subnav:
  //           "/investor-relations/shareholder-information/latest-shareholders-news",
  //       },
  //       {
  //         menu: "Press Releases and Disclosures",
  //         subnav: [
  //           {
  //             nav: "Acquisitions, Partnerships and Others",
  //             link: "/investor-relations/press-releases-and-disclosures/acquisitions-partnerships-and-others",
  //           },
  //           {
  //             nav: "Board Meeting / Stockholders' Related Disclosures",
  //             link: "/investor-relations/press-releases-and-disclosures",
  //           },
  //           {
  //             nav: "Capex Related Disclosures",
  //             link: "/investor-relations/press-releases-and-disclosures/capex-related-disclosures",
  //           },
  //           {
  //             nav: "Financial Results",
  //             link: "/investor-relations/press-releases-and-disclosures/financial-results",
  //           },
  //           {
  //             nav: "News Clarifications Filed with PSE",
  //             link: "/investor-relations/press-releases-and-disclosures/news-clarifications-filed-with-pse",
  //           },
  //           {
  //             nav: "Organizational Announcements",
  //             link: "/investor-relations/press-releases-and-disclosures/organizational-announcements",
  //           },
  //           {
  //             nav: "Share Buy-Back Disclosures",
  //             link: "/investor-relations/press-releases-and-disclosures/share-buy-back-disclosures",
  //           },
  //           {
  //             nav: "Sustainability",
  //             link: "/investor-relations/press-releases-and-disclosures/sustainability",
  //           },
  //           {
  //             nav: "Maya Innovations Holdings Pte. Ltd. (VIH)",
  //             link: "/investor-relations/press-releases-and-disclosures/voyager-paymaya",
  //           },
  //         ],
  //       },
  //       {
  //         menu: "Financial Results",
  //         subnav: "/investor-relations/financial-results",
  //       },
  //       {
  //         menu: "Presentations",
  //         subnav: "/investor-relations/presentations",
  //       },
  //       {
  //         menu: "Podcasts",
  //         subnav: "/investor-relations/podcasts",
  //       },
  //       {
  //         menu: "Shareholder Information",
  //         subnav: [
  //           {
  //             nav: "Overview",
  //             link: "/investor-relations/shareholder-information",
  //           },
  //           {
  //             nav: "Beneficial Ownership",
  //             link: "/investor-relations/shareholder-information/beneficial-ownership",
  //           },
  //           {
  //             nav: "Dividend Info",
  //             link: "/investor-relations/shareholder-information/dividend-info",
  //           },
  //           {
  //             nav: "GIS Archive",
  //             link: "/investor-relations/shareholder-information/latest-shareholders-news/gis-archive",
  //           },
  //           {
  //             nav: "Public Ownership Report",
  //             link: "/investor-relations/shareholder-information/public-ownership-report",
  //           },
  //           {
  //             nav: "Latest Shareholders' News",
  //             link: "/investor-relations/shareholder-information/latest-shareholders-news",
  //           },
  //           {
  //             nav: "Shareholding Structure",
  //             link: "/investor-relations/shareholder-information/shareholding-structure",
  //           },
  //           {
  //             nav: "Top 100 Stockholders",
  //             link: "/investor-relations/shareholder-information/top-100-stockholders",
  //           },
  //           {
  //             nav: "Transfer Agents",
  //             link: "/investor-relations/shareholder-information/transfer-agents",
  //           },
  //         ],
  //       },
  //       {
  //         menu: "Credit Ratings",
  //         subnav: "/investor-relations/credit-ratings-reports",
  //       },
  //       {
  //         menu: "PLDT U.S. Dollar Bond",
  //         subnav: "/investor-relations/pldt-u-s-dollar-bond",
  //       },
  //       {
  //         menu: "PLDT Retail Bonds",
  //         subnav: "/investor-relations/pldt-retail-bond",
  //       },
  //       {
  //         menu: "Calendar of Events",
  //         subnav: "/investor-relations/calendar-of-events",
  //       },
  //       {
  //         menu: "Analysts and Investors Contact",
  //         subnav: "/investor-relations/analysts-and-investors-contact",
  //       },
  //     ],
  //   },
  //   {
  //     main: "Careers",
  //     submenu: "https://careers.pldt.com.ph/",
  //     type: "ext",
  //   },
  // ];

  const [openDropdown, setOpenDropdown] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [logo, setLogo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [activeIndex, setActiveIndex] = useState(null);
  const [desktopmenu, setDesktopMenu] = useState([]);

  const staticLogo = "temp-assets/header-logo.png";

  const processMenuUri = (uri) => {
    // Check if URI starts with "base:"
    if (uri.startsWith("base:")) {
      // Replace "base:" with "/"
      return uri.replace("base:", "/");
    }
    // If it's an external link, return the original URI
    return uri;
  };

  const transformMenu = (menuItems) => {
    const items = menuItems.map((item) => {
      let newItem = {
        main: item.title,
        pathname: processMenuUri(item.uri), // For internal links
        submenu: item.below
          ? transformMenu(item.below)
          : processMenuUri(item.uri),
      };
      return newItem;
    });
    return items;
  };

  useEffect(() => {
    Promise.all([getGLobalHeader(), getHeaderMenu()])
      .then(() => setLoading(false))
      .catch((err) => {
        // Handle the error gracefully
        // Optionally log it in the console if needed
        console.error(err); // Uncomment if you want to log it for debugging
      });
  }, []);

  const getHeaderMenu = () => {
    const fetchURL = pldtConfig.apiUrl + "api/menu_items/header-navigation";

    return fetch(fetchURL)
      .then((response) => response.json())
      .then((response) => {
        const desktopmenu = transformMenu(response);
        setDesktopMenu(desktopmenu);
      })
      .catch((err) => {
        // Suppress the error here if needed
        console.error("Failed to fetch menu items", err); // Optional
      });
  };

  const getGLobalHeader = () => {
    const fetchURL = pldtConfig.apiUrl + "api/v1/global-header";

    return fetch(fetchURL)
      .then((response) => response.json())
      .then((response) => {
        const { logo } = response[0];
        setLogo(pldtConfig.apiUrl + logo);
      })
      .catch((err) => {
        // Suppress the error here if needed
        console.error("Failed to fetch global header", err); // Optional
      });
  };

  const toggleDropdown = (index) => {
    setActiveIndex(index);
    setOpenDropdown(openDropdown === index ? null : index);
  };

  const toggleSearchBar = () => {
    setIsOpen((prevState) => !prevState);
  };

  const handleBrokenImage = () => {
    setLogo(staticLogo);
  };

  const isExternalUrl = (url) => {
    // If the URL doesn't include a protocol, treat it as relative to the current origin
    const link = new URL(url, window.location.origin);
    return link.origin !== window.location.origin;
  };

  return (
    <header>
      <nav className="c-header">
        <div className="top-nav">
          <div className="items">
            <div className="ctas">
              <Link to="/contact-us">Contact Us</Link>
              <button onClick={toggleSearchBar}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M21.5306 20.4693L16.8366 15.7762C18.1971 14.1428 18.8755 12.0478 18.7307 9.92691C18.5859 7.80604 17.629 5.82265 16.0591 4.38932C14.4892 2.95599 12.4272 2.18308 10.3019 2.23138C8.17666 2.27968 6.15184 3.14547 4.64867 4.64864C3.1455 6.15181 2.27971 8.17663 2.23141 10.3019C2.18311 12.4271 2.95602 14.4892 4.38935 16.0591C5.82268 17.629 7.80607 18.5859 9.92694 18.7307C12.0478 18.8755 14.1429 18.1971 15.7763 16.8365L20.4694 21.5306C20.5391 21.6003 20.6218 21.6556 20.7128 21.6933C20.8039 21.731 20.9015 21.7504 21 21.7504C21.0985 21.7504 21.1961 21.731 21.2872 21.6933C21.3782 21.6556 21.4609 21.6003 21.5306 21.5306C21.6003 21.4609 21.6556 21.3782 21.6933 21.2871C21.731 21.1961 21.7504 21.0985 21.7504 21C21.7504 20.9014 21.731 20.8038 21.6933 20.7128C21.6556 20.6218 21.6003 20.539 21.5306 20.4693ZM3.75 10.5C3.75 9.16495 4.14588 7.8599 4.88758 6.74987C5.62928 5.63984 6.68349 4.77467 7.91689 4.26378C9.15029 3.75289 10.5075 3.61922 11.8169 3.87967C13.1262 4.14012 14.329 4.78299 15.273 5.727C16.217 6.671 16.8599 7.87374 17.1203 9.18311C17.3808 10.4925 17.2471 11.8497 16.7362 13.0831C16.2253 14.3165 15.3601 15.3707 14.2501 16.1124C13.1401 16.8541 11.835 17.25 10.5 17.25C8.7104 17.248 6.99466 16.5362 5.72922 15.2708C4.46378 14.0053 3.75199 12.2896 3.75 10.5Z"
                    fill="white"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div className="bottom-nav">
          <div className="items">
            <Link to="/" className="logo">
              {loading ? (
                <img src={staticLogo} alt="PLDT Logo" />
              ) : (
                <img src={logo} alt="PLDT Logo" onError={handleBrokenImage} />
              )}
            </Link>
            <ul className="menus">
              {desktopmenu.map((item, index) => {
                const url = item.pathname || item.submenu;

                return (
                  <li key={index} className="menu-item">
                    {Array.isArray(item.submenu) ? (
                      <MenuDropdown
                        activeIndex={activeIndex}
                        menuIndex={index}
                        isOpen={openDropdown === index}
                        title={item.main}
                        toggleDropdown={toggleDropdown}
                        submenu={item.submenu}
                      />
                    ) : isExternalUrl(url) ? (
                      <a
                        className="link-item"
                        href={url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {item.main}
                      </a>
                    ) : (
                      <Link className="link-item" to={url}>
                        {item.main}
                      </Link>
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <MobileNav menus={desktopmenu} logo={logo} />
        <SearchBar isOpen={isOpen} />
      </nav>
    </header>
  );
};

export default Header;
