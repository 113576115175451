import React from "react";
import { useHistory } from "react-router-dom";

const FooterRedirectBtn = ({ label, url, customClass = "" }) => {
//   console.log("🚀 ~ FooterRedirectBtn ~ url:", url)
  const history = useHistory();

  const handleNavigation = () => {
    const isExternal =
      new URL(url, window.location.href).origin !== window.location.origin;

    if (isExternal) {
      window.open(url, "_blank", "noopener noreferrer");
    } else {
      history.push(url); // For internal navigation
    }
  };

  return (
    <button className={customClass} onClick={handleNavigation}>
      {label}
    </button>
  );
};

export default FooterRedirectBtn;
