import React, { useState } from "react";

const DropdownMenuMobile = ({
  menuIndex,
  isOpen,
  title,
  submenu,
  toggleDropdown,
}) => {
  const handleToggle = () => {
    toggleDropdown(menuIndex);
  };

  const [openDropdown, setOpenDropdown] = useState(null);

  const toggleSubmenuDropdown = (index) => {
    setOpenDropdown(openDropdown === index ? null : index);
  };

  return (
    <div className="c-dropdown-menu-mobile">
      <div className="content" onClick={handleToggle}>
        {title}
        <svg
          className={isOpen ? "rotate-90" : null}
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M14.0813 10.3313L7.83128 16.5813C7.74242 16.6641 7.62489 16.7092 7.50345 16.707C7.38201 16.7049 7.26615 16.6557 7.18026 16.5698C7.09438 16.4839 7.04518 16.368 7.04304 16.2466C7.0409 16.1252 7.08598 16.0076 7.16878 15.9188L13.0867 10L7.16878 4.08128C7.08598 3.99242 7.0409 3.87489 7.04304 3.75345C7.04518 3.63201 7.09438 3.51615 7.18026 3.43026C7.26615 3.34438 7.38201 3.29518 7.50345 3.29304C7.62489 3.2909 7.74242 3.33598 7.83128 3.41878L14.0813 9.66878C14.1691 9.75667 14.2184 9.87581 14.2184 10C14.2184 10.1242 14.1691 10.2434 14.0813 10.3313Z"
            fill="#1B1B1B"
          />
        </svg>
      </div>
      {/* {isOpen ? ( */}
      <ul className={`list ${isOpen ? "active" : ""}`}>
        {submenu.map((item, index) => (
          <li className="list-item" key={index}>
            {Array.isArray(item.subnav) ? (
              <div className="c-mobile-sub-sub">
                <div
                  onClick={() => toggleSubmenuDropdown(index)}
                  className={`c-mobile-sub-sub__menu ${
                    openDropdown === index ? "is-open" : ""
                  }`}
                >
                  {item.menu}
                  <svg
                    className={`${openDropdown === index ? "rotate-90" : ""}`}
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                  >
                    <path
                      d="M14.0813 10.8313L7.83128 17.0813C7.74242 17.1641 7.62489 17.2092 7.50345 17.207C7.38201 17.2049 7.26615 17.1557 7.18026 17.0698C7.09438 16.9839 7.04518 16.868 7.04304 16.7466C7.0409 16.6252 7.08598 16.5076 7.16878 16.4188L13.0867 10.5L7.16878 4.58128C7.08598 4.49242 7.0409 4.37489 7.04304 4.25345C7.04518 4.13201 7.09438 4.01615 7.18026 3.93026C7.26615 3.84438 7.38201 3.79518 7.50345 3.79304C7.62489 3.7909 7.74242 3.83598 7.83128 3.91878L14.0813 10.1688C14.1691 10.2567 14.2184 10.3758 14.2184 10.5C14.2184 10.6242 14.1691 10.7434 14.0813 10.8313Z"
                      fill="#1B1B1B"
                    />
                  </svg>
                </div>
                {/* {openDropdown === index ? ( */}
                <ol
                  className={`c-mobile-sub-sub__list ${
                    openDropdown === index ? "active" : ""
                  }`}
                >
                  {item.subnav.map((itemSubnav, subIndex) => (
                    <li className="subnavs-item" key={subIndex}>
                      <a className="link" href={itemSubnav.link}>
                        {itemSubnav.nav}
                      </a>
                    </li>
                  ))}
                </ol>
                {/* ) : null} */}
              </div>
            ) : (
              <a href={item.subnav}>{item.menu}</a>
            )}
          </li>
        ))}
      </ul>
      {/* ) : null} */}
    </div>
  );
};

export default DropdownMenuMobile;
